import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _567c36f7 = () => interopDefault(import('../pages/forget_password.vue' /* webpackChunkName: "pages/forget_password" */))
const _4a8438a4 = () => interopDefault(import('../pages/inspire.vue' /* webpackChunkName: "pages/inspire" */))
const _26093a33 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _c5a7ff9e = () => interopDefault(import('../pages/reset_password.vue' /* webpackChunkName: "pages/reset_password" */))
const _bd5c3432 = () => interopDefault(import('../pages/administration/bus_stops.vue' /* webpackChunkName: "pages/administration/bus_stops" */))
const _56dc8f63 = () => interopDefault(import('../pages/administration/buses.vue' /* webpackChunkName: "pages/administration/buses" */))
const _095307c0 = () => interopDefault(import('../pages/administration/drivers.vue' /* webpackChunkName: "pages/administration/drivers" */))
const _71f76f08 = () => interopDefault(import('../pages/administration/roads.vue' /* webpackChunkName: "pages/administration/roads" */))
const _8c739586 = () => interopDefault(import('../pages/administration/users.vue' /* webpackChunkName: "pages/administration/users" */))
const _211b5234 = () => interopDefault(import('../pages/notifications/overview.vue' /* webpackChunkName: "pages/notifications/overview" */))
const _b9960d84 = () => interopDefault(import('../pages/overview/buses.vue' /* webpackChunkName: "pages/overview/buses" */))
const _ff2b5b8c = () => interopDefault(import('../pages/overview/daily_report.vue' /* webpackChunkName: "pages/overview/daily_report" */))
const _5164348e = () => interopDefault(import('../pages/overview/passengers.vue' /* webpackChunkName: "pages/overview/passengers" */))
const _4eafa3c7 = () => interopDefault(import('../pages/overview/reservations.vue' /* webpackChunkName: "pages/overview/reservations" */))
const _58d38f90 = () => interopDefault(import('../pages/system/agents.vue' /* webpackChunkName: "pages/system/agents" */))
const _7c08ef68 = () => interopDefault(import('../pages/system/areas.vue' /* webpackChunkName: "pages/system/areas" */))
const _1545d5e3 = () => interopDefault(import('../pages/system/availabilities.vue' /* webpackChunkName: "pages/system/availabilities" */))
const _337cd669 = () => interopDefault(import('../pages/system/depots.vue' /* webpackChunkName: "pages/system/depots" */))
const _1dc8bac4 = () => interopDefault(import('../pages/system/holiday.vue' /* webpackChunkName: "pages/system/holiday" */))
const _f3fb40d6 = () => interopDefault(import('../pages/system/operators.vue' /* webpackChunkName: "pages/system/operators" */))
const _7a47d718 = () => interopDefault(import('../pages/system/prices.vue' /* webpackChunkName: "pages/system/prices" */))
const _aefac066 = () => interopDefault(import('../pages/system/statistics.vue' /* webpackChunkName: "pages/system/statistics" */))
const _06b7a939 = () => interopDefault(import('../pages/systemlog/overview.vue' /* webpackChunkName: "pages/systemlog/overview" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/forget_password/",
    component: _567c36f7,
    pathToRegexpOptions: {"strict":true},
    name: "forget_password"
  }, {
    path: "/inspire/",
    component: _4a8438a4,
    pathToRegexpOptions: {"strict":true},
    name: "inspire"
  }, {
    path: "/login/",
    component: _26093a33,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/reset_password/",
    component: _c5a7ff9e,
    pathToRegexpOptions: {"strict":true},
    name: "reset_password"
  }, {
    path: "/administration/bus_stops/",
    component: _bd5c3432,
    pathToRegexpOptions: {"strict":true},
    name: "administration-bus_stops"
  }, {
    path: "/administration/buses/",
    component: _56dc8f63,
    pathToRegexpOptions: {"strict":true},
    name: "administration-buses"
  }, {
    path: "/administration/drivers/",
    component: _095307c0,
    pathToRegexpOptions: {"strict":true},
    name: "administration-drivers"
  }, {
    path: "/administration/roads/",
    component: _71f76f08,
    pathToRegexpOptions: {"strict":true},
    name: "administration-roads"
  }, {
    path: "/administration/users/",
    component: _8c739586,
    pathToRegexpOptions: {"strict":true},
    name: "administration-users"
  }, {
    path: "/notifications/overview/",
    component: _211b5234,
    pathToRegexpOptions: {"strict":true},
    name: "notifications-overview"
  }, {
    path: "/overview/buses/",
    component: _b9960d84,
    pathToRegexpOptions: {"strict":true},
    name: "overview-buses"
  }, {
    path: "/overview/daily_report/",
    component: _ff2b5b8c,
    pathToRegexpOptions: {"strict":true},
    name: "overview-daily_report"
  }, {
    path: "/overview/passengers/",
    component: _5164348e,
    pathToRegexpOptions: {"strict":true},
    name: "overview-passengers"
  }, {
    path: "/overview/reservations/",
    component: _4eafa3c7,
    pathToRegexpOptions: {"strict":true},
    name: "overview-reservations"
  }, {
    path: "/system/agents/",
    component: _58d38f90,
    pathToRegexpOptions: {"strict":true},
    name: "system-agents"
  }, {
    path: "/system/areas/",
    component: _7c08ef68,
    pathToRegexpOptions: {"strict":true},
    name: "system-areas"
  }, {
    path: "/system/availabilities/",
    component: _1545d5e3,
    pathToRegexpOptions: {"strict":true},
    name: "system-availabilities"
  }, {
    path: "/system/depots/",
    component: _337cd669,
    pathToRegexpOptions: {"strict":true},
    name: "system-depots"
  }, {
    path: "/system/holiday/",
    component: _1dc8bac4,
    pathToRegexpOptions: {"strict":true},
    name: "system-holiday"
  }, {
    path: "/system/operators/",
    component: _f3fb40d6,
    pathToRegexpOptions: {"strict":true},
    name: "system-operators"
  }, {
    path: "/system/prices/",
    component: _7a47d718,
    pathToRegexpOptions: {"strict":true},
    name: "system-prices"
  }, {
    path: "/system/statistics/",
    component: _aefac066,
    pathToRegexpOptions: {"strict":true},
    name: "system-statistics"
  }, {
    path: "/systemlog/overview/",
    component: _06b7a939,
    pathToRegexpOptions: {"strict":true},
    name: "systemlog-overview"
  }, {
    path: "/",
    redirect: "/overview/buses/",
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
